import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  // UNAUTHORIZED
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('pages/UnauthorizedPage.vue'),
    meta: {
      requiresGuest: true
    }
  },
  // AUTHORIZED
  {
    path: '/',
    component: () => import('src/layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('pages/IndexPage.vue')
      },
      // USERS
      {
        path: 'users',
        children: [
          {
            path: '',
            name: 'Users',
            component: () => import('pages/users/UsersIndex.vue')
          },
          {
            path: ':id',
            name: 'UserShow',
            component: () => import('pages/users/UserShow.vue')
          }
        ]
      },
      // BETA USERS
      {
        path: 'beta-users',
        children: [
          {
            path: '',
            name: 'BetaUsers',
            component: () => import('pages/beta-users/BetaUsersIndex.vue')
          },
          {
            path: ':id',
            name: 'BetaUserShow',
            component: () => import('pages/beta-users/BetaUserShow.vue')
          }
        ]
      },
      {
        path: 'discussion-comments',
        children: [
          {
            path: '',
            name: 'DiscussionComments',
            component: () => import('pages/discussion-comments/DiscussionCommentsIndex.vue')
          }
        ]
      },
      // TYPE-AHEAD (JAVA) SANDBOX
      {
        path: 'type-ahead-sandbox',
        children: [
          {
            path: '',
            name: 'TypeAheadSandbox',
            component: () => import('pages/type-ahead-sandbox/TypeAheadSandboxIndex.vue')
          }
        ]
      },
      // ARTICLE EXPLORER
      {
        path: 'article-explorer',
        children: [
          {
            path: '',
            name: 'ArticleExplorer',
            component: () => import('pages/article-explorer/ArticleExplorerIndex.vue'),
            children: [
              {
                path: ':model/:id',
                name: 'ArticleExplorerShow',
                component: () => import('pages/article-explorer/ArticleExplorerShow.vue')
              }
            ]
          }
        ]
      },
      // ACTIVITY LOG
      {
        path: 'activity-log',
        children: [
          {
            path: '',
            name: 'ActivityLog',
            component: () => import('pages/activity-log/ActivityLogIndex.vue')
          },
          {
            path: ':id',
            name: 'ActivityLogShow',
            component: () => import('pages/activity-log/ActivityLogShow.vue')
          }
        ]
      },
      // APP CONFIGURATION
      {
        path: 'configuration',
        children: [
          {
            path: '',
            name: 'Configuration',
            component: () => import('pages/configuration/ConfigurationIndex.vue')
          }
        ]
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
